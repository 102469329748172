import { useEffect, useState } from 'react'
import iconInsta from './Instagram_icon.png.webp'
import iconMenu from './iconeMenu.png'
import iconMenuX from './iconeMenuX.png'

const Nav = (props) => {
    const bairros = []
    const locais = []
    const categorias = []

    props.roles.map((item) => {if(item.categoria != null && categorias.filter(elemento => elemento == item.categoria)[0] == undefined){categorias.push(item.categoria)}})
    props.eventosFiltrado.map((item) => {if(item.bairro != null && bairros.filter(elemento => elemento == item.bairro)[0] == undefined){bairros.push(item.bairro)}})
    props.eventosFiltrado.map((item) => {if(locais.filter(elemento => elemento == item.local)[0] == undefined){locais.push(item.local)}})

    useEffect(() => props.setMenuIsOpen(false), [props.categoria, props.subcategoria, props.busca, props.selectBairro, props.selectLocal, props.selectData])

    return(
        <div className={`fixed bg-white w-screen duration-700 overflow-clip z-50 text-xl ${props.menuIsOpen == true ? 'h-72' : 'h-16'} md:flex md:h-auto justify-around border-b-2 md:px-5 lg:px-20 md:text-base`}>
            <div className="flex items-center justify-between py-3 md:w-4/12 px-5 md:px-2 lg:px-5">
                <a target='__blank' href='https://www.instagram.com/bomroleofc/'><img src={iconInsta} className='w-7 mx-4 hover:scale-125 duration-200' /></a>
                <div className="flex text-3xl font-bold mx-2">Bom Role</div>
                <div className='flex  items-center justify-around'>
                    <select value={props.gratis} onChange={(evento) => {
                        props.setGratis(evento.target.value)
                        props.setsubcategoria(false)
                    }}>
                        <option value=''>Valor</option>
                        <option value='Gratis'>Gratis</option>
                        <option value='Pago'>Pago</option>
                    </select>
                    {props.gratis == 'Pago' ? 
                        <div className='w-7/12 md:w-7/12'>
                            <label>até: R$</label>
                            <input type='number' className='border w-4/12 md:w-6/12 max-w-12 h-1/5' onChange={(evento) => props.setSelectValor(parseInt(evento.target.value))} />
                        </div>
                    : props.gratis == 'Gratis' ? props.setSelectValor(1) : props.setSelectValor(0)}
                </div>
                {props.menuIsOpen == true ?
                    <img onClick={() => props.setMenuIsOpen(!props.menuIsOpen)} className="md:hidden w-12" src={iconMenuX} />
                    :
                    <img onClick={() => props.setMenuIsOpen(!props.menuIsOpen)} className="md:hidden w-12" src={iconMenu} />
                }
            </div>

            <div className='flex flex-col mt-4 m-auto md:flex-row md:m-0 w-4/6 md:w-5/6 gap-5 items-cente'>
                <div className='flex justify-around md:w-7/12'>
                    <div className='flex flex-col w-full h-full justify-center gap-2 md:flex-row md: justify-around'>
                        {categorias.sort().map((item) => {
                            return(
                                <button key={item} onClick={() => {
                                    props.setCategoria(item)
                                    props.setsubcategoria(false)
                                }}>{item}</button>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav