const fazGet = (url) => {
    let request = new XMLHttpRequest()
    request.open("GET", url, false)
    request.send()
    return request.responseText
}

export const lista = (url) => {
    let lista = JSON.parse(fazGet(url))
    console.log('Carregou!')
    return lista
}