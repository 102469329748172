import Cartao from './Cartao.js'
import FIltroDatas from './FIltroDatas.js'
import ControladorPaginas from './ControladorPaginas.js'

const Eventos = (props) => {
    const qntPorPagina = 12
    const indexInicio = (props.paginaAtual - 1) * qntPorPagina
    const indexFinal = props.paginaAtual * qntPorPagina
    const eventosDaPagina = props.eventosFiltrado.slice(indexInicio, indexFinal)

    return(
        <div className='flex flex-col w-full items-center' onClick={() => props.setMenuIsOpen(false)}>
            <div className='flex gap-4 w-10/12'>
                <FIltroDatas datas={props.datas} setSelectData={props.setSelectData} setsubcategoria={props.setsubcategoria} />
                <select value={props.subcategoria} className='flex w-2/8 gap-8 overflow-x-scroll' onChange={(evento) => props.setsubcategoria(evento.target.value)}>
                    <option value=''>Categoria</option>
                    {props.subcategorias.map((item) => {
                        return(
                            <option value={item}>{item}</option>
                        )
                    })}
                </select>
            </div>
            <div className='flex py-4'>{props.eventosFiltrado.length} Roles encontrados.</div>
            <div className="w-11/12 px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:sm:grid-cols-4 gap-10">
                {eventosDaPagina.map((item) => {
                    return(
                        <Cartao role={item} />
                    )
                })}
            </div>
            <ControladorPaginas
                eventosFiltrado={props.eventosFiltrado}
                paginaAtual={props.paginaAtual}
                qntPorPagina={qntPorPagina}
                setPaginaAtual={props.setPaginaAtual}
            />
        </div>
    )
}

export default Eventos