const ControladorPaginas = (props) => {
  const qntPaginas = props.eventosFiltrado.length / props.qntPorPagina
  
  return(
    <div className='flex text-xl gap-4 p-10'>
      <div className="cursor-pointer" onClick={() => {if(props.paginaAtual > 1){props.setPaginaAtual(props.paginaAtual - 1)}}}>Anterior</div>
      <div>{props.paginaAtual} de {qntPaginas > parseInt(qntPaginas) ? parseInt(qntPaginas) + 1 : qntPaginas}</div>
      <div className="cursor-pointer" onClick={() => {
        if(props.paginaAtual < qntPaginas)
        props.setPaginaAtual(props.paginaAtual + 1)
      }}>Proxima</div>
    </div>
  )
}

export default ControladorPaginas