const FIltroDatas = (props) => {
    const datasClin = (lista) => {
        const datasSegundos = []
        lista.map((item => {
            if(datasSegundos.filter(elemento => elemento == `${item.split('/')[2]}-${item.split('/')[1]}-${item.split('/')[0]}`)[0] == undefined){
                datasSegundos.push(`${item.split('/')[2]}-${item.split('/')[1]}-${item.split('/')[0]}`)
            }
        }))
        return datasSegundos
    }

    return (
        <select className='flex w-2/8 gap-4' onChange={(evento) => {
            props.setSelectData(evento.target.value)
            props.setsubcategoria('')
        }}>
            {datasClin(props.datas).sort().map((item) => {
                const diaSemana = {6: 'Dom', 0: 'Seg', 1: 'Ter', 2: 'Qua', 3: 'Qui', 4: 'Sex', 5: 'Sab'}
                const feira = new Date(item).getDay()
                const hoje = `${new Date().toLocaleString('pt-BR').split(',')[0].split('/')[2]}-${new Date().toLocaleString('pt-BR').split(',')[0].split('/')[1]}-${new Date().toLocaleString('pt-BR').split(',')[0].split('/')[0]}`
                const amanha = `${new Date(new Date(hoje).setDate(new Date().getDate() + 1)).toLocaleString('pt-BR').split(',')[0].split('/')[2]}-${new Date(new Date(hoje).setDate(new Date().getDate() + 1)).toLocaleString('pt-BR').split(',')[0].split('/')[1]}-${new Date(new Date(hoje).setDate(new Date().getDate() + 1)).toLocaleString('pt-BR').split(',')[0].split('/')[0]}`
                return(
                    //<option value={item} className='flex'>{new Date().toLocaleString('pt-BR').split(', ')[0] == item ? 'Hoje' : new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleString('pt-BR').split(',')[0] == item ? 'Amanha' : `${feira} ${item.slice(-10, 5)}`}</option>
                    <option value={`${item.split('-')[2]}/${item.split('-')[1]}/${item.split('-')[0]}`}>
                        {item == hoje ? `Hoje - ${diaSemana[feira]}` : item == amanha ? `Amanha - ${diaSemana[feira]}` : `${diaSemana[feira]} ${item.split('-')[2]}/${item.split('-')[1]}`}
                    </option>
                )
            })}
        </select>
    )
}

export default FIltroDatas