const Cartao = (props) => {
    const diaSemana = {6: 'Domingo', 0: 'Segunda', 1: 'Terça', 2: 'Quarta', 3: 'Quinta', 4: 'Sexta', 5: 'Sábado'}
    const meses = {1: 'Janeiro', 2: 'Fevereiro', 3: 'Março', 4: 'Abril', 5: 'Maio', 6: 'Junho', 7: 'Julho', 8: 'Agosto', 9: 'Setembro', 10: 'Outubro', 11: 'Novembro', 12: 'Dezembro'};
    const feira = new Date(`${props.role.data.split('/')[2]}-${props.role.data.split('/')[1]}-${props.role.data.split('/')[0]}`).getDay()

    return(
        <a className='w-full h-full border-2 rounded-xl shadow-md hover:shadow-2xl hover:scale-105 duration-200 overflow-clip' key={props.role.nome + props.role.data} href={props.role.link} target='_blank'>
            <img src={props.role.img} className='w-full' />
            <div className="flex flex-col gap-3 p-3 text-sm">
                <div className="font-medium">{props.role.nome}</div>
                <div>{props.role.categoria}</div>
                <div className="flex justify-center gap-2">
                    {JSON.parse(props.role.subcategoria).map((item) => {
                        return(
                            <div className="text-xs border p-1 rounded-xl">{item}</div>
                        )
                    })}
                </div>
                <div className='flex text-sm gap-2 justify-between'>
                    <div className="font-bold">{props.role.local}</div>
                    <div>{props.role.bairro}</div>
                </div>
                <div className="flex justify-around">
                    <div className="flex flex-col items-center">
                        <div className="bg-black text-white px-2 font-black text-xl">{diaSemana[feira]}</div>
                        <div className="font-black text-3xl">{props.role.data.split('/')[0]}</div>
                        <div className="text-xl">{meses[parseInt(props.role.data.split('/')[1])]}</div>
                    </div>
                    <div className="flex flex-col items-center justify-around">
                        <div className="flex items-center gap-1">
                            <img className="h-5" src="https://cdn-icons-png.flaticon.com/512/151/151770.png" />
                            <div className="text-xl">{props.role.horaInicio}</div>
                        </div>
                        <div className="flex items-center text-3xl">R${props.role.valor}</div>
                    </div>
                    
                </div>
            </div>
        </a>
    )
}

export default Cartao